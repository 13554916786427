import Tarif from '../Components/Tarif';
import InfoFiability from '../Components/InfoFiability';
import '../Styles/InfoFiability.css';
import styles from '../Styles/Animation.module.css';
import { useEffect, useRef } from 'react';
import Faq from '../Components/Faq';
import Footer from '../Components/Footer';
import Testimonial from '../Components/Testimonial';
import {  isMobile } from 'react-device-detect';




export default function LandingPage() {



    const useInViewport = (options) => {
        const observer = useRef(null);
      
        useEffect(() => {
          observer.current = new IntersectionObserver(
            (entries) => {
              entries.forEach((entry) => {
                entry.target.classList.toggle("is-inViewport", entry.isIntersecting);
              });
            },
            options
          );
       
          return () => {
            if (observer.current) {
              observer.current.disconnect();
            }
          };
        }, [options]);
      
        const observe = (element) => {
          if (observer.current && element) {
            observer.current.observe(element);
          }
        };
      
        return { observe };
      };

      const { observe } = useInViewport({
        threshold: isMobile ? 0.1 : 0.3,
      });

    
      useEffect(() => {
        document.querySelectorAll('[data-inviewport]').forEach((el) => {
          observe(el);
        });
      }, [observe]);
      
    return (
        <div>
            <InfoFiability/>
            <Tarif/>
            <Testimonial/>
            <Faq/>
            <Footer/>
        </div>
    )
}