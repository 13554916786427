import './App.css'

import { Routes, Route, BrowserRouter } from 'react-router-dom';
import Nav from './Components/Nav';
import LandingPage from './Pages/LandingPage';


function App() {

  return (
    <>

      <div className="App">
        <Nav/>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<LandingPage/>}></Route>
          </Routes>
        </BrowserRouter>
      </div>

    </>
  )
}

export default App
