import Benefit from './Benefit';
import lottie from "lottie-web";
import { defineElement } from "@lordicon/element";
import '../Styles/InfoFiability.css';
import styles from '../Styles/Animation.module.css';
import {  isMobile } from 'react-device-detect';


defineElement(lottie.loadAnimation);


const benefitsContent = (
  <div className='container-fiability__sections'>
    <Benefit
      title="Livraison Éclair"
      description="Une qualité et une rapidité inégalées ! Bénéficiez d'une livraison express de votre site internet en seulement 24 à 72 heures, adaptée à vos besoins spécifiques."
      icon="https://cdn.lordicon.com/zzjjvkam.json"
    />
    <Benefit
      title="Un prix accessible à tous"
      description="Explorez une offre exceptionnelle pour faciliter la réalisation de votre projet. Notre approche unique en matière de prix garantit une accessibilité inégalée. Notre engagement est de rendre votre vision entrepreneuriale réalisable en vous présentant des solutions innovantes qui s'adaptent à vos besoins financiers."
      icon="https://cdn.lordicon.com/nkfxhqqr.json"
    />
    <Benefit
      title="Une équipe disponible 24/7"
      description="Pour assurer pleinement votre satisfaction, notre service est disponible 24/7 pour écouter vos suggestions ou répondre à vos questions."
      icon="https://cdn.lordicon.com/wzrwaorf.json"
    />
  </div>
);




const InfoFiability = () => {


  return (
    <div data-inviewport="fade-in" className='container-fiability' id='solution'>
      <div className={styles['container-fiability__container']}>
        <div  className='container-fiability__container__left'>
          <img 
            src='img-violet.jpeg'
            style={{width: '100%', height: '100%', borderRadius: '10px'}}
          />
        </div>
        <div className='container-fiability__container__right'>
        <div  className='container-fiability__container__right__container-encar'>
            <p className='container-fiability__container__right__container-encar__p'>Notre solution</p>
          </div>
          <h1  className='container-fiability__container__right__title'>De la pensée à la création en <span className='container-fiability__container__right__title__span'>un instant</span></h1>
          <p   className='container-fiability__container__right__description'>Aujourd'hui, la présence en ligne est cruciale pour propulser votre projet.  Nous sommes conscient des défis financiers souvent liés à la création d'un business, c'est pourquoi nous avons conçu notre service avec des prix défiant toute concurrence. Nous avons décidé de canaliser notre savoir-faire au service de votre site web. Notre équipe de développeurs chevronnés et de designers passionnés s'engage à vous offrir une expérience exceptionnellement rapide et qualitative. Avec nous, bénéficiez d'un service incomparable, où professionnalisme rime avec rapidité et excellence.</p>
        </div>
      </div>
      {isMobile ? benefitsContent : <div data-inviewport="fade-in">{benefitsContent}</div>}

    </div>
  );
};

export default InfoFiability;
