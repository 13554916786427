import '../Styles/FaqStyle.css';
import FaqComponent from './FaqComponent';
import styles from '../Styles/Animation.module.css';



export default function Faq(){

    const questionResponse = [

        {
            question: "Qu'est-ce qui vous distingue et pourquoi devrais-je choisir votre service plutôt qu'un concurrent ?",
            response: "Nous mettons en avant la qualité de notre expertise en développement et design, associée à des prix ultra-compétitifs, afin de vous offrir une solution unique que vous ne trouverez nulle part ailleurs."
        },
        {
            question: " Est-il possible de gérer certaines parties de mon site par moi-même après vos services ?",
            response: "Tout à fait ! Dans le cadre de nos services, nous mettons à votre disposition une interface d'administration auquel vous seul aurez accès. Si besoin, notre équipe sera ravie d'organiser un appel dédié pour que vous puissiez prendre en main votre back-office de la meilleur des manière"
        },
        {
            question: "Est-ce difficile de gérer mon site via votre interface d'administration ?",
            response: "Nous offrons un rendez-vous privé totalement gratuit d'une heure pour vous guider à travers l'administration de votre espace, vous permettant ainsi de devenir totalement autonome dans la gestion de votre site internet !"
        },
        {
            question: "Est-ce que le nom de domaine est inclus dans le service de création de site internet ?",
            response: "Oui ! Le nom de domaine est compris dans notre service de création de site internet, tant que le coût du domaine ne dépasse pas 30 euros. De plus, notre équipe se charge de la configuration et de la mise en place du nom de domaine."
        }


    ]




    return(
        <div data-inviewport="fade-in" id='faq' className={styles['container-faq']}>
            <div className="container-faq__header">
                <h1 className='container-faq__title'>Questions & réponses</h1>
                <p className='container-faq__sous-title'>On répond à toutes vos questions les plus fréquentes juste ici.</p>
            </div>

            <h2 className='container-faq__frequency-question'>Questions fréquentes :</h2>

            {questionResponse?.map((item, index) => {
                return(
                    <FaqComponent
                        key={index}
                        question={item.question}
                        response={item.response}
                    />
                )
            })}

        </div>
    )
}