import styles from '../Styles/Animation.module.css';
import {  isMobile } from 'react-device-detect';


export default function TestimonialComponent({pseudo, message, initial, typeProject, colorPhotoProfil}) {

    const svgElement = (
      <img style={{width: '15px' }} src='startosvg.png' />
      );

      const svgElements = Array.from({ length: 5 }, (_, index) => (
        <div key={index} className='container-testimonial__container-body__container-text'>
          {svgElement}
        </div>
      ));

    return(
        <div data-inviewport="fade-in" className={styles['container-testimonial__container-body__each']}>
         
            <div className='container-testimonial__container-body__container-text'>
                <div className="container-testimonial__container-body__container-text__pseudo-photo-profil">
                  <div style={{display : 'flex', justifyContent: 'space-between', width : '100%'}}>

                  <div style={{display: 'flex', alignItems: 'center', gap: '4px'}}>


                    <div 
                      className='container-testimonial__container-body__container-text__pseudo-photo-profil__pp'
                      style={{backgroundColor: colorPhotoProfil}}
                    >
                      <p className='container-testimonial__container-body__container-text__pseudo-photo-profil__pp__text'>{initial}</p>
                    </div>
                    <h3 className='container-testimonial__container-body__container-text__pseudo-photo-profil__pseudo'>{pseudo}</h3>
                    </div>


                      <div>  
                        {
                          !isMobile &&
                            <lord-icon 
                                src="https://cdn.lordicon.com/chhdhony.json"
                                trigger="in"
                                delay="500"
                                state="in-reveal"
                                style={{width: '40px', height: 'fit'}}
                            >
                            </lord-icon>
                        }
                      </div>  
                      </div>
                </div>

                <h4 style={{textAlign : 'left'}}>{typeProject}</h4>
                <div className="container-testimonial__container-body__container-text__star">{svgElements}</div> 
                <p className='container-testimonial__container-body__container-text__message'><i>{message}</i></p>
                
            </div>   
        </div>
    )
}      