import { Link } from "react-scroll";
import {  isMobile } from 'react-device-detect';


export default function FooterComponent({icon, title, text, idRedirection, redirectLink, offset}){

    return(
        <div className='container-footer__content__each'>
            <lord-icon
                src={icon}
                trigger="hover"
                colors="primary:#cbb193,secondary:#ffffff"
                style={{width: '40px' ,height: '40px'}}>
            </lord-icon>
            <p className='container-footer__content__each__p1'>{title}</p>
            <p className='container-footer__content__each__p2'>{text}</p>
            <div className='container-footer__content__learn-more'>
                <Link
                    to={idRedirection} 
                    onClick={redirectLink} 
                    spy={true} smooth={true} 
                    offset={isMobile ? offset : 0} 
                    duration={500}
                >
                    <p className='container-footer__content__learn-more__p'>en savoir plus</p>
                </Link>
                <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 24 24"><path fill="#cbb193" d="M12.6 12L8.7 8.1q-.275-.275-.275-.7t.275-.7q.275-.275.7-.275t.7.275l4.6 4.6q.15.15.213.325t.062.375q0 .2-.063.375t-.212.325l-4.6 4.6q-.275.275-.7.275t-.7-.275q-.275-.275-.275-.7t.275-.7l3.9-3.9Z"/></svg>
            </div>
        </div>
    )
}