import { useState } from 'react';

export default function FaqComponent({question, response}){

    const [clickOnQuestion, setClickOnQuestion] = useState(false);

    return(
        <div className="container-faq__question">
            <div className='container-faq__question__response'>
                <div
                    onClick={() => setClickOnQuestion(!clickOnQuestion)} 
                    className='container-faq__question__response__answer'>
                    <p className='container-faq__question__response__answer__question'>{question}</p>

                        <lord-icon
                            src={clickOnQuestion ? "https://cdn.lordicon.com/dykoqszm.json" : "https://cdn.lordicon.com/zrkkrrpl.json"}
                            trigger="in"
                            delay="100"
                            state="in-reveal"
                            style={{minWidth: '18px', minHeight: '18px', maxWidth: '18px', maxHeight: '18px' }}>
                        </lord-icon>

                </div>
                <p className={clickOnQuestion ? 'container-faq__question__response__p__display' : 'container-faq__question__response__p'}>
                    {response}
                </p>
            </div>
        </div>
    )
}