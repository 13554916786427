import '../Styles/TarifStyle.css';
import lottie from "lottie-web";
import { defineElement } from "@lordicon/element";
import Forfait from './Forfait'
import { fonctionnalityAvailableShopify, fonctionnalityAvailableWordpress, fonctionnalityAvailableSurMesure } from '../helper/dictionnary';
import styles from '../Styles/Animation.module.css';
import Modal from './Modal';
import { useState } from 'react';



defineElement(lottie.loadAnimation);



export default function Tarif(){

    const [ModalOpen, setModalOpen] = useState(false);

    const TabForfait = [
        {
            backgroundColor: "#F3FBF9",
            typeSiteTitle: "Shopify",
            typeSiteColorAndButton: "#86DAC8",
            SentenceSousTitle: "Pour démarrez votre boutique en ligne",
            price: "399€",
            arr: fonctionnalityAvailableShopify,
            titleButton: "Opter pour l'e-commerce",
            whichPromotion: "shopify",
            firstPrice: true
        },
        {
            backgroundColor: "#FFF3EF",
            typeSiteTitle: "WordPress",
            typeSiteColorAndButton: "#FF8762",
            SentenceSousTitle: "Pour mettre en valeur votre entreprise services ou activités",
            price: "599€",
            arr: fonctionnalityAvailableWordpress,
            titleButton: "Opter pour WordPress",
            whichPromotion: "wordpress",
            firstPrice: true
        },
        {
            backgroundColor: "#F1F6FE",
            typeSiteTitle: "sur mesure",
            typeSiteColorAndButton: "#6EA1F7",
            SentenceSousTitle: "Pour la mise en œuvre de projets de grande ampleur ",
            price: "Prix selon devis",
            fontSizePrice: "30px",
            arr: fonctionnalityAvailableSurMesure,
            titleButton: "Opter pour un site sur mesure",
            firstPrice: false
        }
    ]
    return(
        <div>
        <Modal
          isOpen={ModalOpen}
          close={() => setModalOpen(false)}
        />

            <div data-inviewport="fade-in" id='tarif' className={styles['container-tarif']}>
                <h1 className='container-tarif__title'>Un tarif imbattable pour démarrer votre activité </h1>
                    <p className='container-tarif__sous-title'>Transformez votre vision en réalité avec nos tarifs attractifs. </p>
                    <div className='container-tarif__container-offer'>
                        {TabForfait?.map((item, index) => {
                            return(
                                <Forfait
                                    key={index}
                                    backgroundColor={item.backgroundColor}
                                    typeSiteTitle={item.typeSiteTitle}
                                    typeSiteColorAndButton={item.typeSiteColorAndButton}
                                    SentenceSousTitle={item.SentenceSousTitle}
                                    price={item.price}
                                    fontSizePrice={item.fontSizePrice}
                                    arr={item.arr}
                                    titleButton={item.titleButton}
                                    whichPromotion={item.whichPromotion}
                                    firstPrice={item.firstPrice}
                                    openModal={() => setModalOpen(true)}
                                />
                            )
                      })}
                    </div>
            </div>



        </div>
    )
}