

export default function Forfait(
    {
        backgroundColor,
        typeSiteTitle,
        typeSiteColorAndButton,
        SentenceSousTitle,
        backgroundColorButton,
        titleButton,
        price,
        fontSizePrice = "40px",
        arr,
        whichPromotion = '',
        firstPrice,
        openModal
        
    }){


    return (
        <div style={{backgroundColor: backgroundColor}} className='container-tarif__container-offer__each'>
            <div className="container-tarif__container-offer__each__center">
                <p className='container-tarif__container-offer__each__p'>Site web <span className='container-tarif__container-offer__each__p__span' style={{color: typeSiteColorAndButton}}>{typeSiteTitle}</span></p>
                <p className='container-tarif__container-offer__each__second-p'>{SentenceSousTitle}</p>
                <div className="container-tarif__container-offer__each__container">
                {firstPrice ? (
                    <p className="container-tarif__container-offer__each__price__a-partir">À partir de {firstPrice}</p>
                ) : null}
                    <h4 style={{fontSize: fontSizePrice}} className='container-tarif__container-offer__each__price'>{price}</h4>
                    {
                        whichPromotion === 'shopify' ? (
                            <div className='container-tarif__container-offer__each__container-promo'>
                                <img src='promotionShopify.png' />
                            </div>
                        ) : whichPromotion === 'wordpress' ? (
                            <div className='container-tarif__container-offer__each__container-promo'>
                                <img src='promotionWordpress.png' />
                            </div>
                        ) : null
                    }


            
                </div>

                <div onClick={openModal} className='container-tarif__container-offer__each__button' style={{backgroundColor: typeSiteColorAndButton}}>
                    <p className='container-tarif__container-offer__each__button__p'>{titleButton}</p>
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24"><path fill="currentColor" d="M16.175 13H5q-.425 0-.713-.288T4 12q0-.425.288-.713T5 11h11.175l-4.9-4.9q-.3-.3-.288-.7t.313-.7q.3-.275.7-.288t.7.288l6.6 6.6q.15.15.213.325t.062.375q0 .2-.063.375t-.212.325l-6.6 6.6q-.275.275-.687.275T11.3 19.3q-.3-.3-.3-.713t.3-.712L16.175 13Z"/></svg>
                </div>
            </div>

            <p className='container-tarif__container-offer__each__title-fonctionnality'>Fonctionnalités offre {typeSiteTitle} :</p>

                {arr?.map((fonctionnality, index) => (
                    <div
                        key={index}
                        className='container-tarif__container-offer__each__fonctionality'
                    >
                        <div
                            style={{backgroundColor: typeSiteColorAndButton}} 
                            className='container-tarif__container-offer__each__fonctionality__container-check'>
                            <lord-icon
                                src="https://cdn.lordicon.com/wyjhrzdh.json"
                                trigger="loop"
                                style={{ width: "17px", height: "17px" }}
                            >
                            </lord-icon>
                        </div>

                        <p
                            className='container-tarif__container-offer__each__fonctionality__p'
                        >
                            {fonctionnality?.name}
                        </p>
                    </div>
                ))}

        </div>
    )
}