
    export const fonctionnalityAvailableShopify = [
        {
            id: 0,
            name: 'Site web Shopify Complet',
        },
        {
            id: 1,
            name: 'Intégration de modules spécifiques selon vos besoins',
        },
        {
            id: 2,
            name: 'Nom de domaine inclus',
        },
        {
            id: 3,
            name: 'Livraison entre 24h à 72h',
        },
        {
            id: 4,
            name: '3 révisions offertes',
        },

    ]

    export const fonctionnalityAvailableWordpress = [
        {
            id: 1,
            name: 'Nom de domaine inclus',
        },
            //     {
            //         id: 2,
            //         name: 'Livraison entre 3 à 5 jours',
            //     },
        {
            id: 2,
            name: 'Livraison entre 1 à 2 semaines',
        },
        {
            id: 3,
            name: '4 révisions offertes',
        },
            //     {
            //         id: 1,
            //         name: 'Pack design (logo + bannière)',
            //     },
        {
            id: 1,
            name: 'Formation personnalisée pour mettre à jour votre site en toute autonomie',
        },
        {
            id: 1,
            name: 'Options complémentaires',
        },
    ]

    export const fonctionnalityAvailableSurMesure = [
        {
            id: 0,
            name: 'Site web entièrement personnalisé',
        },
        {
            id: 1,
            name: 'Plateforme SaaS sur mesure',
        },
        {
            id: 2,
            name: 'Conception et développement d\'applications mobiles',
        },
        {
            id: 3,
            name: 'Amélioration du référencement naturel (SEO)',
        },
        {
            id: 3,
            name: 'Gestion proactive de la relation client',
        },
    ]

    export const tabTestimonialsMobile = [
        [
            {
                icon: 'https://cdn.lordicon.com/rahouxil.json',
                pseudo: 'Christan L',
                message: '"Très satisfait des services proposés, l\'équipe a su comprendre mes besoins et créer une boutique E-commerce qui correspond parfaitement à mon activité. Je recommande vivement!"',
                initial: 'CL',
                typeProject: 'Boutique E-commerce spécialisée dans la vente de produits d\'urgence',
                colorPhotoProfil: '#5EBBB9'

            },
            {
                icon: 'https://cdn.lordicon.com/rahouxil.json',
                pseudo: 'Thomas M',
                message: '"Une collaboration exceptionnelle! Du début à la fin, l\'équipe a fait preuve de professionnalisme et d\'efficacité. Mon site internet est au-delà de mes attentes. Merci pour tout!"',
                initial: 'TM',
                typeProject: 'Site Vitrine lié à artisanat traditionnel',
                colorPhotoProfil: '#E8904B'
            },
            {
                icon: 'https://cdn.lordicon.com/rahouxil.json',
                pseudo: 'Laurent G',
                message: '"Rapides, efficaces, et le résultat parle de lui-même. Mon site web représente parfaitement mon entreprise."',
                initial: 'LG',
                typeProject: 'Boutique E-commerce de gadgets et accessoires pour les passionnés de voyages et d\'exploration',
                colorPhotoProfil: '#3E6DAD'
            },
            {
                icon: 'https://cdn.lordicon.com/rahouxil.json',
                pseudo: 'Marie D',
                message: '"Une très bonne expérience avec Rémi, quelqu\'un de professionnel, passionné et réactif ! Un vrai contact humain, force de proposition et accessible, je suis très content de notre collaboration !"',
                initial: 'MD',
                typeProject: 'Site Vitrine pour une entreprise de recyclage',
                colorPhotoProfil: '#33691F'
            },
            {
                icon: 'https://cdn.lordicon.com/rahouxil.json',
                pseudo: 'Renaud L',
                message: '"Réactivité, disponibilité à toutes les heures du jour et de la nuit. De très bons conseils pour le rédactionnel et le graphisme. Très pro, même après avoir été payé (rare de nos jours). Toujours à l\'écoute et à la pointe de la technologie."',
                initial: 'RL',
                typeProject: 'Site Vitrine pour un refuge animalier',
                colorPhotoProfil: '#3E6DAD'
            },
            {
                icon: 'https://cdn.lordicon.com/rahouxil.json',
                pseudo: 'Enzo C',
                message: '"Je suis vraiment satisfait de la prestation de TonSiteInternet. Travail soigné et nickel. Toute l\'équipe est à votre écoute et très réactive."',
                initial: 'EC',
                typeProject: 'Boutique E-commerce proposant des équipements et fournitures pour les amateurs de camping et de plein air.',
                colorPhotoProfil: '#7E57C2'
            },
            {
                icon: 'https://cdn.lordicon.com/rahouxil.json',
                pseudo: 'Clément H',
                message: 'Développeur très pro, à l\'écoute, offrant de très bons conseils. Connaissant son métier avec perfection. Disponible, et s\'investit à fond pour réaliser le projet. Très satisfait du résultat . Merci.',
                initial: 'CH',
                typeProject: 'Site Vitrine pour une entreprise de construction écologique',
                colorPhotoProfil: '#699F37'
            },
            {
                icon: 'https://cdn.lordicon.com/rahouxil.json',
                pseudo: 'Cristrine F',
                message: '"Je suis très satisfaite de mon site internet, mes critères ont bien été respectés. Compétence et patience avec ceux qui ne maitrisent pas l’informatique sont des qualités que j’ai trouvé chez TonSiteInternet"',
                initial: 'RY',
                typeProject: 'Boutique E-commerce de produits de décoration d\'intérieur écologiques',
                colorPhotoProfil: '#33691F'
            },
        ]
    ]
        
    export const tabTestimonials = [
        [
            {
                icon: 'https://cdn.lordicon.com/rahouxil.json',
                pseudo: 'Christan L',
                message: '"Très satisfait des services proposés, l\'équipe a su comprendre mes besoins et créer une boutique E-commerce professionnel qui correspond parfaitement à mon activité. Je recommande vivement!"',
                initial: 'CL',
                typeProject: 'Boutique E-commerce spécialisée dans la vente de produits d\'urgence',
                colorPhotoProfil: '#5EBBB9'

            },
            {
                icon: 'https://cdn.lordicon.com/rahouxil.json',
                pseudo: 'Thomas M',
                message: '"Une collaboration exceptionnelle! Du début à la fin, l\'équipe a fait preuve de professionnalisme et d\'efficacité. Mon site internet est au-delà de mes attentes. Merci pour tout!"',
                initial: 'TM',
                typeProject: 'Site Vitrine lié à artisanat traditionnel',
                colorPhotoProfil: '#E8904B'
            },
            {
                icon: 'https://cdn.lordicon.com/rahouxil.json',
                pseudo: 'Laurent G',
                message: '"Rapides, efficaces, et le résultat parle de lui-même. Mon site web représente parfaitement mon entreprise."',
                initial: 'LG',
                typeProject: 'Boutique E-commerce de gadgets et accessoires pour les passionnés de voyages et d\'exploration',
                colorPhotoProfil: '#3E6DAD'
            },
            {
                icon: 'https://cdn.lordicon.com/rahouxil.json',
                pseudo: 'Marie D',
                message: '"Une très bonne expérience avec Rémi, quelqu\'un de professionnel, passionné et réactif ! Un vrai contact humain, force de proposition et accessible, je suis très content de notre collaboration !"',
                initial: 'MD',
                typeProject: 'Site Vitrine pour une entreprise de recyclage',
                colorPhotoProfil: '#33691F'
            },
            {
                icon: 'https://cdn.lordicon.com/rahouxil.json',
                pseudo: 'Renaud L',
                message: '"Réactivité, disponibilité à toutes les heures du jour et de la nuit. De très bons conseils pour le rédactionnel et le graphisme. Très pro, même après avoir été payé (rare de nos jours). Toujours à l\'écoute et à la pointe de la technologie."',
                initial: 'RL',
                typeProject: 'Site Vitrine pour un refuge animalier',
                colorPhotoProfil: '#3E6DAD'
            },
            {
                icon: 'https://cdn.lordicon.com/rahouxil.json',
                pseudo: 'Enzo C',
                message: '"Je suis vraiment satisfait de la prestation de TonSiteInternet. Travail soigné et nickel. Toute l\'équipe est à votre écoute et très réactive."',
                initial: 'EC',
                typeProject: 'Boutique E-commerce proposant des équipements et fournitures pour les amateurs de camping et de plein air.',
                colorPhotoProfil: '#7E57C2'
            },
            {
                icon: 'https://cdn.lordicon.com/rahouxil.json',
                pseudo: 'Clément H',
                message: '"Développeur très pro, à l\'écoute, offrant de très bons conseils. Connaissant son métier avec perfection. Disponible, et s\'investit à fond pour réaliser le projet. Très satisfait du résultat . Merci."',
                initial: 'CH',
                typeProject: 'Site Vitrine pour une entreprise de construction écologique',
                colorPhotoProfil: '#699F37'
            },
            {
                icon: 'https://cdn.lordicon.com/rahouxil.json',
                pseudo: 'Cristrine F',
                message: '"Je suis très satisfaite de mon site internet, mes critères ont bien été respectés. Compétence et patience avec ceux qui ne maitrisent pas l’informatique sont des qualités que j’ai trouvé chez TonSiteInternet"',
                initial: 'RY',
                typeProject: 'Boutique E-commerce de produits de décoration d\'intérieur écologiques',
                colorPhotoProfil: '#33691F'
            },
            
            // ... (autres témoignages pour le premier onglet)
        ],
        [
            {
                icon: 'https://cdn.lordicon.com/rahouxil.json',
                pseudo: 'Gabriel B',
                message: 'Nous tenons à vous remercier pour votre remarquable travail accompli. Notre boutique est vraiment magnifique! Votre équipe est professionnel. Cordialement.',
                initial: 'GB',
                typeProject: 'Boutique E-commerce de produits de décoration d\'intérieur écologiques',
                colorPhotoProfil: '#AAD267'
            },
            {
                icon: 'https://cdn.lordicon.com/rahouxil.json',
                pseudo: 'Xavier M',
                message: 'Très bonne expérience avec Rémi. A l\'écoute, très sympathique et surtout efficace vis à vis de ce que l\'on souhaitait!',
                initial: 'XM',
                typeProject: 'Refonte d\'un site Vitrine pour une école en ligne de magie',
                colorPhotoProfil: '#F4521E'
            },
            {
                icon: 'https://cdn.lordicon.com/rahouxil.json',
                pseudo: 'Ali B',
                message: 'D\'un très grand professionnalisme, toujours disponible et à l\'écoute. Très satisfait de notre boutique qui correspond à nos attentes. Nous recommandons TonSiteInternet et n\'hésiterons pas à faire à nouveau appel à leur service.',
                initial: 'AB',
                typeProject: 'Boutique E-commerce de produits d\'enceinte connectée',
                colorPhotoProfil: '#5EBBB9'
            },
            {
                icon: 'https://cdn.lordicon.com/rahouxil.json',
                pseudo: 'Thierry L',
                message: '"Second site internet pour lequel j\'ai confié la réalisation à Rémi et je dois dire que je ne suis pas déçu, travail toujours impeccable à la hauteur de mes attentes. Simple, rapide et efficace."',
                initial: 'TL',
                typeProject: 'Site Vitrine pour un musée d\'histoire',
                colorPhotoProfil: '#F4521E'
            },
            {
                icon: 'https://cdn.lordicon.com/rahouxil.json',
                pseudo: 'Patrick S',
                message: '"Création d\'un site répondant à tous les critères demandés...un vrai professionnel."',
                initial: 'RY',
                typeProject: 'Boutique E-commerce / Vente de casquette',
                colorPhotoProfil: '#699F37'
            },
            {
                icon: 'https://cdn.lordicon.com/rahouxil.json',
                pseudo: 'François P',
                message: '"J\'ai contacter TonSiteInternet par précipitation pour faire une carte d\'invitation via un site internet. J\'ai été très bien conseillé et le résultat est superbe. Merci pour votre professionnalisme."',
                initial: 'FP',
                typeProject: 'Site Vitrine sous forme de carte d\'invitation pour un mariage',
                colorPhotoProfil: '#7E57C2'
            },
            {
                icon: 'https://cdn.lordicon.com/rahouxil.json',
                pseudo: 'Sebastien M',
                message: '"J\'ai choisi "TonSiteInternet" pour la création de ma boutique en ligne, et je n\'aurais pas pu faire un meilleur choix. À vivement recommander.',
                initial: 'SM',
                typeProject: 'Boutique E-commerce spécialisée dans les kits de bricolage',
                colorPhotoProfil: '#AAD267'
            },
            {
                icon: 'https://cdn.lordicon.com/rahouxil.json',
                pseudo: 'Manon R',
                message: '"Ma boutique web à été créé dans un délai très court, il est parfaitement opérationnel. Les services de cette entreprise sont également incroyables car l\'équipe est réactive et professionnelle"',
                initial: 'MR',
                typeProject: 'Boutique E-commerce de produits alimentaires exotiques et rares',
                colorPhotoProfil: '#F4521E'
            },
            // ... (autres témoignages pour le deuxième onglet)
        ],
        [
            {
                icon: 'https://cdn.lordicon.com/rahouxil.json',
                pseudo: 'Valentin D',
                message: 'Un créateur de site super à l’écoute D’un professionnalisme exceptionnel très réactif enfin je la recommande vivement même les clients m’ont Félicités de mon site internet félicitations Rémi continuez !!!',
                initial: 'JO',
                typeProject: 'Site E-commerce vente de maillot de football rétro ',
                colorPhotoProfil: '#5EBBB9'
            },
            {
                icon: 'https://cdn.lordicon.com/rahouxil.json',
                pseudo: 'Camil B',
                message: 'Travailler avec Rémi a été un vrai plaisir ! Son expertise et son professionnalisme ont fait toute la différence. Je suis extrêmement satisfait du résultat final et je n\'hésiterai pas à le recommander à mon entourage.',
                initial: 'CB',
                typeProject: 'Site Vitrine lié à une association de protection des animaux',
                colorPhotoProfil: '#7E57C2'
            },
            {
                icon: 'https://cdn.lordicon.com/rahouxil.json',
                pseudo: 'Daniel R',
                message: 'Je suis vraiment impressionné par le travail de Rémi. Il a su comprendre mes besoins et les traduire en un site web optimal. Les compliments affluent et je ne peux que recommander ses services !',
                initial: 'DR',
                typeProject: 'Site Vitrine lié à un cabinet de conseil en gestion de patrimoine',
                colorPhotoProfil: '#7E57C2'
            },

            // ... (autres témoignages pour le troisième onglet)
        ],
    ];


// j'ai envie 


