import '../Styles/TestimonialStyle.css';
import TestimonialComponent from './TestimonialComponent';
import { useState } from 'react';
import styles from '../Styles/Animation.module.css';
import { tabTestimonials, tabTestimonialsMobile } from '../helper/dictionnary';
import {  isMobile } from 'react-device-detect';



export default function Testimonial() {
    
    const testimonialClass = isMobile ? 'container-testimonial__container-body__testimonial-mobile' : 'container-testimonial__container-body__testimonial';



    const [selectedElement, setSelectedElement] = useState(0);

    const handleElementClick = (index) => {
        if (index === selectedElement - 1 && selectedElement > 0) {
            // Clic sur la flèche gauche
            setSelectedElement(index);
        } else if (index === selectedElement + 1 && selectedElement < 2) {
            // Clic sur la flèche droite
            setSelectedElement(index);
        } else if (index >= 0 && index <= 2) {
            // Clic sur l'un des trois points
            setSelectedElement(index);
        }
    };
    return (
        <div data-inviewport="fade-in" id='testimonial' className={styles['container-testimonial']}>
            {/* <div className='container-testimonial__container-header'>
                <h2 className='container-testimonial__container-header__title'>Les avis de nos clients</h2>
                <img className='container-testimonial__container-header__img' src={`${window.origin}/public/img/arrow-right2.svg`}/>
                <p className='container-testimonial__container-header__description'>Explorez les retours de nos clients pour avoir un <span className='container-testimonial__container-header__description__span'> aperçu de leur expérience avec "TonSiteInternet". </span>  Explorez comment notre contribution a façonné le succès de nos clients, faisant de nous leur choix privilégié pour des solutions web de qualité.</p>
            </div> */}

            <div className="container-faq__header">
                <h1 className='container-faq__title'>Voici ce que nos clients disent</h1>
                <p className='container-faq__sous-title'>Découvrez ce que nos clients pensent de notre service à travers leurs témoignages authentiques.</p>
            </div>



            <div className='container-testimonial__container-select'>
                <div className='container-testimonial__container-select__line'></div>
                <div className='container-testimonial__container-select__round__container'>
                    {Array.from({ length: 3 }, (_, i) => (
                        <div
                            key={i}
                            onClick={() => handleElementClick(i)}
                            className={`container-testimonial__container-select__round ${
                                selectedElement === i ? 'selected' : ''
                            }`}
                        ></div>
                    ))}
                </div>
            </div>
            <div className='container-testimonial__round-arrow'>
                <div 
                    onClick={() => handleElementClick(selectedElement - 1)}
                    className={`container-testimonial__round-arrow__round ${
                        selectedElement === 0 ? 'disabled' : ''
                    }`}
                >
                <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24"><path fill="#ffffff" d="m7.85 13l2.85 2.85q.3.3.288.7t-.288.7q-.3.3-.712.313t-.713-.288L4.7 12.7q-.3-.3-.3-.7t.3-.7l4.575-4.575q.3-.3.713-.287t.712.312q.275.3.288.7t-.288.7L7.85 11H19q.425 0 .713.288T20 12q0 .425-.288.713T19 13H7.85Z"/></svg>

                </div>
                <div 
                    onClick={() => handleElementClick(selectedElement + 1)}
                    key="right-arrow"
                    className={`container-testimonial__round-arrow__round ${
                        selectedElement === 2 ? 'disabled' : ''
                    }`}
                >
                    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24"><path fill="#ffffff" d="M16.15 13H5q-.425 0-.713-.288T4 12q0-.425.288-.713T5 11h11.15L13.3 8.15q-.3-.3-.288-.7t.288-.7q.3-.3.713-.313t.712.288L19.3 11.3q.15.15.213.325t.062.375q0 .2-.063.375t-.212.325l-4.575 4.575q-.3.3-.712.288t-.713-.313q-.275-.3-.288-.7t.288-.7L16.15 13Z"/></svg>
                </div>
            </div>
            
            <div className='container-testimonial__container-body'>
                {(isMobile ? tabTestimonialsMobile : tabTestimonials)?.map((testimonials, tabIndex) => (
                <div key={tabIndex} className={`${testimonialClass} ${tabIndex === selectedElement ? 'visible' : ''}`}>
                        {testimonials?.map((testimonial, index) => (
                            <TestimonialComponent
                                key={index}
                                icon={testimonial.icon}
                                pseudo={testimonial.pseudo}
                                message={testimonial.message}
                                initial={testimonial.initial}
                                typeProject={testimonial.typeProject}
                                colorPhotoProfil={testimonial.colorPhotoProfil}
                            />
                        ))}
                    </div>
                ))}
            </div>


        </div>
    )
}