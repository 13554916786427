import React, { useRef, useEffect } from 'react';
import '../Styles/Modal.css';

export default function Modal({ isOpen = false, close }) {
    const modalRef = useRef(null);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (modalRef.current && !modalRef.current.contains(event.target)) {
                close();
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [close]);

    return isOpen ? (
        <div className="modal-container">
            <div ref={modalRef} className='modal-container__container'>
                <div className='modal-container__container__close'>
                    <h1 onClick={close} className='modal-container__container__close__text'>&times;</h1>
                </div>
                <div className='modal-container__container__content'>
                    <img src="laposte.png" alt="placeholder" />
                    <h3 className='modal-container__container__content__title'>Merci de nous faire confiance ! </h3>
                    <p className='modal-container__container__content__description'>Votre satisfaction est notre priorité ! Contactez-nous pour bénéficier de l'aide personnalisée d'un membre de notre équipe pour vous prendre en charge et réaliser votre projet de la meilleur des manières !</p>
                    <div className='modal-container__container__content__container-button'>
                        <a rel="noreferrer" target='_blank' href="https://api.whatsapp.com/send?phone=33602434087&text=Bonjour ! Besoin d'un site internet ? Nous sommes la pour vous !"><button className='modal-container__container__content__container-button__inside-whatsapp'><img src='whatsapp2.webp' alt='whatsapp icon'/>Whatsapp</button></a>
                        <a rel="noreferrer" href= "mailto: contact@tonsiteinternet.fr"><button className='modal-container__container__content__container-button__inside-email'><img src='email_blanc.png' alt='email icon'/>E-mail</button></a>
                        <a rel="noreferrer" target='_blank' href="https://discord.gg/wv6ArWSh3u"><button className='modal-container__container__content__container-button__inside-discord'><img src='discord2.png' alt='whatsapp icon'/>Discord</button></a>
                    </div>
                </div>
            </div>
        </div>
    ) : null;
}
 