export default function Benefit({title, description, icon}){
    return(
        <div className="container-benefit">
            <div className="container-benefit__container-img">
            <lord-icon
                src={icon}
                trigger="loop"
                colors="primary:#A278F5,secondary:#391283"
            >
            </lord-icon>
            </div>

            <p className="container-benefit__title">{title}</p>
            <p className="container-benefit__description">{description}</p>
        </div>
    )
}