import '../Styles/Footer.css';
import FooterComponent from './FooterComponent';
import { useState, useEffect } from 'react';


export default function Footer(){

    const tabFooter = [
        {
            icon: 'https://cdn.lordicon.com/dgpphngr.json',
            title: 'Lancez-vous !',
            text: 'Prêt à démarrer votre activité ? Plongez-vous dans l\'aventure avec nous !',
            id: 'accueil',
            offset: -100
        },
        {
            icon: 'https://cdn.lordicon.com/qucadebu.json',
            title: 'Nos avantages',
            text: 'Explorez nos avantages pour une expérience exceptionnelle et personnalisée.',
            id: 'solution',
            offset: -130
        },
        {
            icon: 'https://cdn.lordicon.com/zlbisnuz.json',
            title: 'Nos tarifs',
            text: 'Découvrez nos tarifs exceptionnels pour une expérience financière inégalée.',
            id: 'tarif',
            offset: -90
        },
        {
            icon: 'https://cdn.lordicon.com/kndkiwmf.json',
            title: 'Nos avis',
            text: 'Faites-nous confiance en consultant nos avis clients les plus récents sur nos services.',
            id: 'solution',
            offset: -130
        },
        {
            icon: 'https://cdn.lordicon.com/xzalkbkz.json',
            title: 'Notre FAQ',
            text: 'Parcourez notre FAQ pour des réponses claires et rapides à vos questions.',
            id: 'faq',
            offset: -60
        },
    ]

    const [selectedItem, setSelectedItem] = useState(0);

    const handleItemClick = (index) => {
      setSelectedItem(index);
    };
  
    const handleLinkClick = (event, index) => {
      event.preventDefault();
      handleItemClick(index);
      };
  

    return(
        <footer className="container-footer">
            <div className='container-footer__header'>
                <p className='container-footer__header__title'>Ton Site Internet</p>
                <div className='container-footer__header__line'></div>
            </div>

            <div className='container-footer__content'>
                {
                    tabFooter.map((item, index) => {
                        return(
                            <FooterComponent
                                key={index}
                                icon={item.icon}
                                title={item.title}
                                text={item.text}
                                idRedirection={item.id}
                                redirectLink={(event) => handleLinkClick(event, index)}
                                offset={item.offset}
                            />
                        )
                    })
                }

            </div>

            <div className='line-footer'></div>

            <p className='container-footer-coypright'>© 2024 TonSiteInternet. Tous droits réservés. Le contenu de ce site web, y compris mais sans s'y limiter, le texte, les images, les graphiques et le code source, est la propriété exclusive de "TonSiteInternet".</p>
        </footer>
    )
}