import { useState, useEffect } from 'react';
import '../Styles/NavStyle.css';
import { Link } from 'react-scroll';
import {  isMobile } from 'react-device-detect';
import Modal from './Modal';



export default function Nav() {
  const luField = () => [
    { name: 'Accueil', link: '/', id: 'accueil', offset: -100 },
    { name: 'Solution', link: '/', id: 'solution', offset: -130 },
    { name: 'Tarif', link: '/', id: 'tarif', offset: -90 },
    { name: 'Témoignages', link: '/', id: 'testimonial', offset: -130},
    { name: 'FAQ', link: '/', id: 'faq', offset: -60},
  ];



  const [ModalOpen, setModalOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(0);

  const handleItemClick = (index) => {
    setSelectedItem(index);
  };

  const handleLinkClick = (event, index) => {
    event.preventDefault();
    handleItemClick(index);
    };

  const [showTransition, setShowTransition] = useState(false);
  const [showTransition2, setShowTransition2] = useState(false);
  const [showTransition3, setShowTransition3] = useState(false);


 
  const [imagePositionY, setImagePositionY] = useState(0);

  // Fonction pour animer l'image de manière douce
  const animateImage = (targetPositionY, duration) => {
    const start = imagePositionY;
    const startTime = performance.now();

    const animate = (currentTime) => {
      const progress = (currentTime - startTime) / duration;
      if (progress < 1) {
        const newPositionY = start + progress * (targetPositionY - start);
        setImagePositionY(newPositionY);
        requestAnimationFrame(animate);
      } else {
        setImagePositionY(targetPositionY);
      }
    };

    requestAnimationFrame(animate);
  };

  // Fonction pour déclencher l'animation
  const moveImage = () => {
    const targetPositionY = imagePositionY === 0 ? 50 : 0; // Changez ces valeurs selon vos besoins
    const duration = 1000; // Durée de l'animation en millisecondes
    animateImage(targetPositionY, duration);
  };

  // Utilisez useEffect pour déclencher l'animation lorsque selectedItem change
  useEffect(() => {
    moveImage();
    const transitionTimeout = setTimeout(() => {
      setShowTransition(true);
    }, 300);
    const transitionTimeout2 = setTimeout(() => {
      setShowTransition2(true);
    }, 700);
    const transitionTimeout3 = setTimeout(() => {
      setShowTransition3(true);
    }, 1300);


    return () => {clearTimeout(transitionTimeout); clearTimeout(transitionTimeout2); clearTimeout(transitionTimeout3)};

  }, []);

  
  return (
    <div id='accueil' className="container-nav">
      <div className='container-img-left-absolute'>
        <img className='container-img-left-absolute__img' src='testleft.png'/>
      </div>

      <div className='container-nav-real-left'>
        <div className="container-nav__container-left">
          <img
            className="container-nav__img"
            src='logo.svg'
            alt="logo"
          />
          <p className="container-nav__name">TonSiteInternet.fr</p>
        </div>

        <div className="container-nav__container-center-left">
        <img
            className="container-nav__img__mobile"
            src='logo.svg'
            alt="logo"
          />
          <div className='container-nav__container-center-left-title'>
            <p className='container-nav__container-center-left-title__p'>Votre site internet</p>
            <p className='container-nav__container-center-left-title__p'>Livré <span className='container-nav__container-center-left-title__p__72'>sous 72h</span></p>
          </div>
          <div className='container-nav__container-center-left-description'>
            <p className='container-nav__container-center-left-description__p'>Besoin d'un site internet rapidement ? Nous sommes là pour vous ! Contactez-nous dès maintenant et recevez votre site sous 72 heures. La solution idéale pour lancer votre présence en ligne sans attendre !</p>
          </div>

          <div onClick={() => setModalOpen(true)} className="container-nav__container-right__container-discord">
            <p className='container-nav__container-right__container-discord__name'>Démarrez votre activité !</p>
          </div>
          
        </div>
        <Modal
          isOpen={ModalOpen}
          close={() => setModalOpen(false)}
        />


      </div>

        <div
          className='container-nav-right'
          style={{ backgroundImage: 'url(replace3.png)' }}
        >
          <div 
            className="container-nav__container-right"
            >
            <ul className="container-nav__container-right__ul">
              {luField()?.map((li, index) => (
                <div 
                  onClick={() => handleItemClick(index)} 
                  key={index}
                  className='container-nav__container-right__ul__container-li'
                >
                  <li className={`container-nav__container-right__ul__li ${
                    selectedItem === index ? 'selected' : ''
                  }`} 
                  >
                    <Link 
                      to={li.id} 
                      onClick={(event) => handleLinkClick(event, index)} 
                      spy={true} smooth={true} 
                      offset={isMobile ? li.offset : 0} 
                      duration={500}
                    >
                      {li.name}
                    </Link>
                  </li>
                  <Link 
                      to={li.id} 
                      onClick={(event) => handleLinkClick(event, index)} 
                      spy={true} smooth={true} 
                      offset={isMobile ? li.offset : 0} 
                      duration={500}
                  >
                  <div
                    className={`container-nav__container-right__ul__line_bottom ${
                      selectedItem === index ? 'visible' : ''
                    }`}
                  ></div>
                  </Link>
                </div>
              ))}
              <div 
                className="container-nav__container-right__container-discord-li"
                onClick={() => setModalOpen(true)}
              >
                <p className='container-nav__container-right__container-discord__name'>Contactez-nous !</p>
              </div>
            </ul>
          </div>


          <div className='container-img-right-absolute'>
            <div className={`container-img-right-absolute__rond-bleu ${showTransition ? 'transition-in' : ''}`}>
              <img className='container-img-right-absolute__rond-bleu__img' src='rond-bleu.png' alt="Test Image"/>
            </div>
            <div className={`container-img-right-absolute__rond-bleu-2 ${showTransition2 ? 'transition-in' : ''}`}>
              <img className='container-img-right-absolute__rond-bleu__img-2' src='rond-bleu.png' alt="Test Image"/>
            </div>
            <div className={`container-img-right-absolute__rond-bleu-3 ${showTransition ? 'transition-in' : ''}`}>
              <img className='container-img-right-absolute__rond-bleu__img-3' src='rond-bleu.png' alt="Test Image"/>
            </div>
            <div className={`container-img-right-absolute__rond-rose ${showTransition2 ? 'transition-in' : ''}`}>
              <img className='container-img-right-absolute__rond-rose__img' src='rond-bleu-clair.png' alt="Test Image"/>
            </div>
            <div className={`container-img-right-absolute__rond-rose-2 ${showTransition3 ? 'transition-in' : ''}`}>
              <img className='container-img-right-absolute__rond-rose__img-2' src='rond-bleu-clair.png' alt="Test Image"/>
            </div>
          </div>


          <div className='container-nav-right__art' style={{ transform: `translateY(${imagePositionY}px)` }}>
            <div className='container-nav-right__art__icon-left'>
              <img className='container-nav-right__art-creatif' src='creatif.png' alt="Test Image"/>
              <img className='container-nav-right__art-guitare' src='la-creativite.png' alt="Test Image"/>
            </div>
              <img className='container-nav-right__art-appel-video' src='appel-video-right-nav.png' alt="Test Image"/>
              <img className='container-nav-right__art-fusee' src='fusee.png' alt="Test Image"/>
          </div>


          
        </div>
    </div>
  );
}
